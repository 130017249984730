import { MaxLength } from '@/lib/const';
import { validateValue } from '@/lib/commonUtil';
import i18n from '@/plugins/i18n';
import moment from 'moment';

// バリデーション
export const validation = {
  // 必須入力
  required: value => !!value,

  // 半角スペースなし
  noSpace: value => (value || '').indexOf(' ') < 0,

  // 最大文字数
  maxLength: (value, maxLength) => !value || value.length <= maxLength,

  // 文字数 (電話番号)
  telNumberLength: value =>
    !!value && validation.pattern(value, 'telNumberLength'),

  // ルール (パターン)
  pattern: (value, label) => !!value && validateValue(value, label),

  // 半角数字の存在チェック
  requiredHalfNumber: value => !(value.match(/[0-9]+/) == null),

  // 半角大文字の存在チェック
  requiredHalfUppercase: value => !(value.match(/[A-Z]+/) == null),

  // 半角小文字の存在チェック
  requiredHalfLowercase: value => !(value.match(/[a-z]+/) == null),

  // 部担コードの入力チェック
  salesCodeCheck: value =>
    (!(value.match(/^[0-9a-zA-Z]*$/) == null) && value.length == 4) || !value,

  // 代理店コードの入力チェック
  agencyCodeCheck: value =>
    (!(value.match(/^[0-9a-zA-Z]*$/) == null) && value.length == 5) || !value,

  // 非同一性チェック
  notConfirmed: (value, target) => !(value == target),

  // 同一性チェック
  confirmed: (value, target) => !(value != target),

  // 必須タグ:{fullNameKanji}
  requiredFullNameKanji: value =>
    !value || (value.match(/{fullNameKanji}/g) || []).length >= 1,

  // 必須タグ:{insuranceName}
  requiredInsuranceName: value =>
    !value || (value.match(/{insuranceName}/g) || []).length >= 1,

  // 必須タグ:{startDate}
  requiredStartDate: value =>
    !value || (value.match(/{startDate}/g) || []).length >= 1,

  // 必須タグ:{lastAccessibleDay}
  requiredLastAccessibleDay: value =>
    !value || (value.match(/{lastAccessibleDay}/g) || []).length >= 1,

  // 必須タグ:{contractUrl}
  requiredContractUrl: value =>
    !value || (value.match(/{contractUrl}/g) || []).length >= 1,

  // 必須タグ:{agencyName}
  requiredAgencyName: value =>
    !value || (value.match(/{agencyName}/g) || []).length >= 1,

  // 必須タグ:{chargePersonName}
  requiredChargePersonName: value =>
    !value || (value.match(/{chargePersonName}/g) || []).length >= 1,

  // 必須タグ:{agencyPhoneNumber}
  requiredAgencyPhoneNumber: value =>
    !value || (value.match(/{agencyPhoneNumber}/g) || []).length >= 1,

  // 必須タグ:{officialWebSiteUrl}
  requiredOfficialWebSiteUrl: value =>
    !value || (value.match(/{officialWebSiteUrl}/g) || []).length >= 1,

  // 必須タグ:{officialLineUrl}
  requiredOfficialLineUrl: value =>
    !value || (value.match(/{officialLineUrl}/g) || []).length >= 1,

  // 必須タグ:{modificationContent}
  requiredModificationContent: value =>
    !value || (value.match(/{modificationContent}/g) || []).length >= 1,

  // 使用不可タグ:{modificationContent}
  unusableModificationContent: value => !value.match(/{modificationContent}/g),

  // 開始日妥当性確認
  futureStartDate: value =>
    !value || moment(value).isSameOrAfter(moment().format('YYYY-MM-DD')),

  // 真偽値確認
  boolean: value => !value || value == 0 || value == 1,
};

// ルール (必須入力)
const requiredRule = value =>
  validation.required(value) || i18n.t('validation.required');

// ルール (半角スペースなし)
const noSpaceRule = value =>
  validation.noSpace(value) || i18n.t('validation.noSpace');

// ルール (最大文字数)
const maxLengthRule = max => value =>
  validation.maxLength(value, max) || i18n.t('validation.max', { length: max });

// ルール (開始日妥当性確認)
const futureStartDateRule = value =>
  validation.futureStartDate(value) || i18n.t('validation.futureStartDate');

// ルール (真偽値確認)
const booleanRule = value =>
  validation.boolean(value) || i18n.t('validation.boolean');

// ルール (文字数（電話番号）)
const telNumberLengthRule = value => validation.telNumberLength(value);

// ルール (パターン)
const patternRule = pattern => value => validation.pattern(value, pattern);

// バリデーション (デフォルト)
export const defaultRules = [requiredRule, maxLengthRule(MaxLength.Default)];

// バリデーション (ユーザー名)
export const userNameRules = [requiredRule, maxLengthRule(MaxLength.Default)];

// バリデーション (募集人ID)
export const recruiterIdRules = [
  patternRule('recruiterId'),
  maxLengthRule(MaxLength.RecruiterId),
];

// バリデーション (ユーザー名)
export const chargePersonNameRules = [
  requiredRule,
  maxLengthRule(MaxLength.Default),
];

// バリデーション (部担コード)
export const salesCodeRules = [
  requiredRule,
  noSpaceRule,
  patternRule('salesCode'),
];

// バリデーション (代理店コード)
export const agencyCodeRules = [
  requiredRule,
  noSpaceRule,
  patternRule('agencyCode'),
];

// バリデーション (管理者)
export const adminRules = [requiredRule, booleanRule];

// バリデーション (アカウント開始日)
export const startDateRules = [
  requiredRule,
  patternRule('date'),
  futureStartDateRule,
];

// バリデーション (コメント)
export const commentdRules = [maxLengthRule(MaxLength.UserComment)];

// バリデーション (電話番号)
export const telRules = [noSpaceRule, requiredRule, telNumberLengthRule];

// バリデーション (メールアドレス)
export const mailRules = [
  noSpaceRule,
  requiredRule,
  patternRule('mailAddress'),
  maxLengthRule(MaxLength.MailAddress),
];

export class Length {
  // 最大文字数より長いかどうか
  static isLongerThanMax = (value, maxLength = MaxLength.Default) => {
    return !value || value.length > maxLength;
  };
}

export default {
  requiredRule,
  noSpaceRule,
  maxLengthRule,
  telNumberLengthRule,
  patternRule,
  defaultRules,
  salesCodeRules,
  agencyCodeRules,
  telRules,
  mailRules,
  userNameRules,
  recruiterIdRules,
  chargePersonNameRules,
  salesCodeRules,
  startDateRules,
  commentdRules,
  adminRules,
  Length,
};
