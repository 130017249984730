import { set } from '@/utils/vuex';
import {
  APPLICATIONFORMPDF,
  APPLICATIONFORMDETAILS,
  APPLICATIONFORMIMAGES,
  APPLICATIONFORMPRINTLOCATION,
} from './types';

export default {
  APPLICATIONFORMPDF: set(APPLICATIONFORMPDF),
  APPLICATIONFORMDETAILS: set(APPLICATIONFORMDETAILS),
  APPLICATIONFORMIMAGES: set(APPLICATIONFORMIMAGES),
  APPLICATIONFORMPRINTLOCATION: set(APPLICATIONFORMPRINTLOCATION),
};
