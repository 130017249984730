var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    [
      _vm.hidden === false
        ? [
            _c("Header"),
            _c("navigation-drawer", { attrs: { links: _vm.links } })
          ]
        : _vm._e(),
      _c("router-view", { on: { updateNavigation: _vm.updateNavigation } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }