import Vue from 'vue';
import Router from 'vue-router';
import paths from './path';
import { BasePaths } from '@/lib/const';
import cognito from '@/plugins/cognito';
import store from '@/store';
import { keepAlive } from '@/apis/common/keepAlive';
import { HttpStatus } from '@/lib/const';

// 接続先情報の取得
const BasePath = BasePaths;

function route(path, view, name, meta) {
  return {
    name: name || view,
    path,
    meta,
    component: resovle =>
      import(`@/pages/${BasePath}/${view}.vue`).then(resovle),
  };
}

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: paths.map(path =>
    route(path.path, path.view, path.name, path.meta)
  ).concat([{ path: '*', redirect: '/' }]),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const toPath = to.matched[0].path ? to.matched[0].path : to.path;
  const httpStatus = await keepAlive().catch(err => {
    return err;
  });
  if (to.name === 'Maintenance') {
    if (httpStatus && httpStatus !== HttpStatus.Maintenance) {
      // 通常時のメンテナンス画面へのアクセスはホーム画面にリダイレクト
      return next('/');
    }
  } else {
    if (httpStatus === HttpStatus.Maintenance) {
      // メンテナンス時はメンテナンス画面に遷移
      return next('/maintenance');
    }
  }

  if (cognitoNeedAuth(toPath)) {
    const isAuhthed = await cognito
      .isAuthenticated()
      .catch(() => next('/login'));
    // 認証が必要なページ遷移時、認証されていない場合、ログイン画面に遷移
    if (!isAuhthed) return next('/login');

    // 権限チェック 参照権限を持たない場合、ホーム画面に遷移
    let userDetail = store.getters['user/userDetail'];

    // ユーザー情報がStoreに設定されていない場合、Cognito認証後、APIサーバーより所得
    if (!userDetail) {
      const currentUser = await cognito.currentUser();

      // APIサーバーよりユーザー情報を取得
      userDetail = await store.dispatch(
        'user/getLoginUser',
        currentUser.username
      );
    }
    const path = paths.find(e => e.path === toPath);

    if (!path.role.includes(userDetail.role)) return next('/');
  }

  next();
});

const cognitoNeedAuth = toPath => {
  const path = paths.find(e => e.path === toPath);
  return path ? path.meta.requiredAuth : false;
};

export default router;
