<template>
  <v-app>
    <Loading />
    <Agency />
  </v-app>
</template>

<script>
import Agency from '@/pages/agency/Main';
import Loading from '@/components/organisms/common/Loading';

export default {
  name: 'App',
  components: {
    Agency,
    Loading,
  },
  data: () => ({}),
  methods: {},
};
</script>

<style lang="scss">
@import '@/styles/index.scss';
</style>
