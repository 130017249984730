import {
  APPLICATIONFORMPDF,
  APPLICATIONFORMDETAILS,
  APPLICATIONFORMIMAGES,
  APPLICATIONFORMPRINTLOCATION,
} from './types';
export default {
  setApplicationFormPdf: ({ commit }, payload) => {
    commit(APPLICATIONFORMPDF, payload);
  },
  setApplicationFormDetails: ({ commit }, payload) => {
    commit(APPLICATIONFORMDETAILS, payload);
  },
  setApplicationFormImages: ({ commit }, payload) => {
    commit(APPLICATIONFORMIMAGES, payload);
  },
  setApplicationFormPrintLocation: ({ commit }, payload) => {
    commit(APPLICATIONFORMPRINTLOCATION, payload);
  },
};
