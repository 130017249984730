import { USER_DETAIL } from '@/store/modules/user/types';
import { getLoginUser } from '@/apis/agency/users';

export default {
  getLoginUser: async ({ commit }, userId) =>
    new Promise(async (resolve, reject) => {
      const userDetail = await getLoginUser(userId).catch(error => {
        console.error(error);
        reject(error);
      });

      if (userDetail) {
        commit(USER_DETAIL, userDetail);
        resolve(userDetail);
      }
    }),
  setUserDetail: async ({ commit }, userDetail) =>
    commit(USER_DETAIL, userDetail),
};
