import {
  APPLICATIONFORMPDF,
  APPLICATIONFORMDETAILS,
  APPLICATIONFORMIMAGES,
  APPLICATIONFORMPRINTLOCATION,
} from './types';

export default {
  applicationFormPdf: state => state[APPLICATIONFORMPDF],
  applicationFormDetails: state => state[APPLICATIONFORMDETAILS],
  applicationFormImages: state => state[APPLICATIONFORMIMAGES],
  applicationFormPrintLocation: state => state[APPLICATIONFORMPRINTLOCATION],
};
