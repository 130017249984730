<template>
  <v-main>
    <template v-if="hidden === false">
      <Header />
      <navigation-drawer :links="links" />
    </template>
    <router-view @updateNavigation="updateNavigation" />
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/organisms/agency/Header';
import NavigationDrawer from '@/components/organisms/agency/NavigationDrawer';
import { nonHeaderPathName, navigationLinks } from '@/lib/const';

export default {
  name: 'Main',
  components: {
    Header,
    NavigationDrawer,
  },
  data() {
    return {
      hidden: true,
      auth: false,
      links: navigationLinks,
    };
  },
  watch: {
    async $route(val) {
      this.$set(
        this,
        'hidden',
        nonHeaderPathName.agency.indexOf(val.name) >= 0
      );
      this.updateNavigation();
    },
  },
  methods: {
    ...mapGetters('user', ['userDetail']),

    // ナビゲーションを更新する
    updateNavigation() {
      const links = JSON.parse(JSON.stringify(navigationLinks));
      // 選択されたリンク
      const selectedLinks = this.selectLinks(links).map(link => ({
        ...link,
        title: this.$t(link.title),
      }));
      this.$set(this, 'links', selectedLinks);
    },

    // 表示するリンクを選択する
    selectLinks(links) {
      return links
        .filter(link => {
          // 参照可な権限を持っている場合
          return link.role && this.userDetail()
            ? link.role.includes(this.userDetail().role)
            : null;
        })
        .filter(link => {
          // グループが配下に存在する場合
          if (link.items) {
            link.items = this.selectLinks(link.items);
            // グループ内に表示可なリンクが存在しない場合、非表示とする
            if (link.items.length === 0) return false;
          }

          return true;
        });
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/index.scss';
</style>
