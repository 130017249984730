import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import modules from './modules';

Vue.use(Vuex);

// ローカルストレージに保管して問題ない情報のみpathsに含めること
export default new Vuex.Store({
  modules,
  plugins: [
    createPersistedState({
      key: 'vuex',
      paths: [],
      storage: window.localStorage,
    }),
  ],
});
