import { render, staticRenderFns } from "./Main.vue?vue&type=template&id=2607a94f&"
import script from "./Main.vue?vue&type=script&lang=js&"
export * from "./Main.vue?vue&type=script&lang=js&"
import style0 from "./Main.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VMain})


/* hot reload */
if (module.hot) {
  var api = require("/Users/imaiyuurika/sj_contract_procedure_contractor_front/admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2607a94f')) {
      api.createRecord('2607a94f', component.options)
    } else {
      api.reload('2607a94f', component.options)
    }
    module.hot.accept("./Main.vue?vue&type=template&id=2607a94f&", function () {
      api.rerender('2607a94f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/agency/Main.vue"
export default component.exports