<template>
  <Dialog ref="dialog" :title="title" width="500px" :color="'error'">
    <v-container>
      <div class="text-center" style="margin: 0 auto;">
        <p>{{ message }}</p>
      </div>
    </v-container>

    <template v-slot:footer>
      <v-btn width="30vw" max-width="170px" class="back" dark @click="close()">
        {{ $t('button.close') }}
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/organisms/agency/Dialog';

export default {
  components: { Dialog },
  data() {
    return {
      title: '',
      message: '',
    };
  },
  methods: {
    open(title, message) {
      (this.title = title), (this.message = message), this.$refs.dialog.open();
    },
    close() {
      this.$refs.dialog.close();
    },
  },
};
</script>
