<template>
  <v-dialog
    v-model="dialog"
    persistent
    :width="width"
    :max-width="maxWidth ? maxWidth : '35vw'"
  >
    <v-card>
      <v-card-title class="justify-center white--text" :class="color">{{
        title
      }}</v-card-title>

      <v-divider></v-divider>

      <slot></slot>

      <v-card-actions class="justify-space-around pb-6">
        <slot name="footer"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: String,
    width: String,
    maxWidth: String,
    color: String,
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
