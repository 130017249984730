import axios from '@/apis/axios';
import { getAccessToken, convertCamelToSnake } from '@/lib/api';

/**
 * ログインユーザー情報取得
 */
export const getLoginUser = async () => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(`/agencies/users/me`, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * ログインユーザー情報更新
 */
export const putLoginUser = async updateParam => {
  const accessToken = await getAccessToken();
  const response = await axios
    .put(
      `/agencies/users/me`,
      { ...updateParam },
      { headers: { 'access-token': accessToken } }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * ユーザー一覧取得
 */
export const getUsers = async (option, condition) => {
  const accessToken = await getAccessToken();
  let params = {};
  if (option) {
    params = Object.assign(params, convertCamelToSnake(option));
  }
  if (condition) {
    params = Object.assign(params, convertCamelToSnake(condition));
  }

  const response = await axios
    .get(`/agencies/users`, {
      params: { ...params },
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * ユーザー取得
 */
export const getUser = async userId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(`/agencies/users/${userId}`, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * ユーザーID取得
 */
export const getUserId = async (userId, params) => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(`/agencies/users/${userId}/userId`, {
      params: { ...params },
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * ユーザー登録
 */
export const postUser = async createParam => {
  const accessToken = await getAccessToken();

  const response = await axios
    .post(
      `/agencies/users`,
      { ...createParam },
      { headers: { 'access-token': accessToken } }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * ユーザー更新
 */
export const putUser = async (userId, updateParam) => {
  const accessToken = await getAccessToken();

  const response = await axios
    .put(
      `/agencies/users/${userId}`,
      { ...updateParam },
      { headers: { 'access-token': accessToken } }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * ユーザー削除
 */
export const deleteUser = async userId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .delete(`/agencies/users/${userId}`, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * ユーザー仮パスワード再発行
 */
export const putUserPasswordResend = async userId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .put(
      `/agencies/users/${userId}/password/resend`,
      {},
      { headers: { 'access-token': accessToken } }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * ユーザーパスワード初期化
 */
export const putUserPasswordReset = async userId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .put(
      `/agencies/users/${userId}/password/reset`,
      {},
      { headers: { 'access-token': accessToken } }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 秘密の質問登録
 */
export const postSecretQuestion = async secretQuestionDetails => {
  const accessToken = await getAccessToken();

  const response = await axios
    .post(
      `/agencies/users/secret_questions`,
      { ...secretQuestionDetails },
      { headers: { 'access-token': accessToken } }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * ユーザ一括登録
 */
export const postUsers = async (users, officeType, userImportId) => {
  const accessToken = await getAccessToken();

  const form = new FormData();
  form.append('users', JSON.stringify(users));
  form.append('officeType', officeType);
  form.append('userImportId', userImportId);

  const response = await axios
    .post(`/agencies/users/bulk_register`, form, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * ユーザ一括更新
 */
export const putUsers = async (users, officeType) => {
  const accessToken = await getAccessToken();

  const form = new FormData();
  form.append('users', JSON.stringify(users));
  form.append('officeType', officeType);

  const response = await axios
    .post(`/agencies/users/bulk_update`, form, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * ユーザー情報集計取得
 */
export const getUserInformationAggregate = async condition => {
  const accessToken = await getAccessToken();
  let params = {};
  params = Object.assign(params, convertCamelToSnake(condition));

  const response = await axios
    .get(`/agencies/users/information/aggregate`, {
      params: { ...params },
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};
