<template>
  <Dialog ref="dialog" :title="$t('label.passwordUpdate')" :color="'next'">
    <v-container class="pt-0 px-8">
      <ValidationObserver ref="observer">
        <v-form lazy-validation ref="form" @submit.prevent>
          <template v-if="isForce && isForceCreateSecretQuestion">
            <!-- 秘密の質問 -->
            <v-row>
              <v-col :class="conditionStyle">
                <ValidationProvider
                  :rules="`selectRequired`"
                  v-slot="{ errors }"
                >
                  <v-select
                    v-model="secretQuestion.questionId"
                    item-text="text"
                    item-value="value"
                    :items="SecretQuestions"
                    :value="secretQuestion.questionId"
                    hide-details
                    :label="$t('label.secretQuestion')"
                  />
                  <ValidationError class="ma-0" :error="errors[0]" />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col :class="conditionStyle">
                <ValidationProvider
                  :rules="`required|max:${MaxLength.SecretQuestionAnswer}`"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="secretQuestion.answer"
                    hide-details
                    :label="$t('label.answer')"
                  />
                  <ValidationError class="ma-0" :error="errors[0]" />
                </ValidationProvider>
              </v-col>
            </v-row>
          </template>
          <!-- 現在のパスワード -->
          <v-row v-show="!isForce">
            <v-col :class="conditionStyle">
              <ValidationProvider
                :rules="
                  `required|min:${MinLength.CognitoPassword}|max:${MaxLength.CognitoPassword}`
                "
                v-slot="{ errors }"
                name="currentPassword"
              >
                <v-text-field
                  v-model="currentPassword"
                  hide-details
                  type="Password"
                  :label="$t('label.editUserInfo.currentPassword')"
                ></v-text-field>
                <ValidationError class="ma-0" :error="errors[0]" />
              </ValidationProvider>
            </v-col>
          </v-row>
          <!-- 新しいパスワード -->
          <v-row>
            <v-col class="mt-0 mb-0 py-0">
              <ValidationProvider
                :rules="
                  `required|requiredHalfNumber|requiredHalfUppercase|requiredHalfLowercase|min:${MinLength.CognitoPassword}|checkIsSameAsTheCurrentPassword:@currentPassword|max:${MaxLength.CognitoPassword}`
                "
                v-slot="{ errors }"
                name="newPassword"
              >
                <v-text-field
                  v-model="newPassword"
                  hide-details
                  type="Password"
                  :label="$t('label.editUserInfo.newPassword')"
                ></v-text-field>
                <ValidationError class="ma-0" :error="errors[0]" />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row class="my-0 py-0">
            {{ $t('label.passwordValidationRule') }}
          </v-row>
          <!-- 新しいパスワード確認 -->
          <v-row>
            <v-col :class="conditionStyle">
              <ValidationProvider
                :rules="
                  `required|checkIsSameAsTheNewPassword:@newPassword|max:${MaxLength.CognitoPassword}`
                "
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="newPasswordConfirm"
                  hide-details
                  type="Password"
                  :label="$t('label.editUserInfo.newPasswordConfirm')"
                ></v-text-field>
                <ValidationError class="ma-0" :error="errors[0]" />
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
    </v-container>

    <template v-slot:footer>
      <v-btn width="33%" class="back" dark @click="close()">
        {{ $t('button.cancel') }}
      </v-btn>
      <v-btn width="33%" class="next" dark @click="onClickUpdateButton()">{{
        $t('button.update')
      }}</v-btn>
    </template>
    <ErrorDialog ref="errorDialog"></ErrorDialog>
    <CompletedDialog
      ref="completedDialog"
      @onSuccess="onComplete"
    ></CompletedDialog>
  </Dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import Dialog from '@/components/organisms/agency/Dialog';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';
import CompletedDialog from '@/components/organisms/agency/CompletedDialog';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import ValidationError from '@/components/organisms/common/ValidationError';
import { MaxLength, MinLength, SecretQuestions } from '@/lib/const';
import { validation } from '@/lib/validation';

/**
 * NOTE:  バリデーションはveeValidation.js上に共通化されているが、
 *        共通ファイル上ではターゲットを取得できなかったためこちらで定義
 */
// パスワードの同一性チェック（現在パスワードと新しいパスワード）
extend('checkIsSameAsTheCurrentPassword', {
  params: ['target'],
  validate(value, { target }) {
    return validation.notConfirmed(value, target);
  },
});
// パスワードの同一性チェック（新しいパスワードと新しいパスワード確認）
extend('checkIsSameAsTheNewPassword', {
  params: ['target'],
  validate(value, { target }) {
    return validation.confirmed(value, target);
  },
});

export default {
  components: {
    Dialog,
    ErrorDialog,
    CompletedDialog,
    ValidationProvider,
    ValidationObserver,
    ValidationError,
  },
  data() {
    return {
      // 編集可能な代理店情報項目
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',

      // 編集フォームのスタイル指定
      conditionStyle: ['mt-0', 'mb-4', 'py-0'],

      // 強制パスワード変更であるかどうか
      isForce: false,

      // 秘密の質問の登録が必要であるかどうか
      isForceCreateSecretQuestion: false,

      // 秘密の質問
      secretQuestion: {
        questionId: null,
        answer: null,
      },
      SecretQuestions: SecretQuestions,

      // 有効文字長
      MaxLength: MaxLength,
      MinLength: MinLength,
    };
  },
  mounted() {},
  methods: {
    ...mapGetters('user', ['userDetail']),
    open() {
      this.$refs.dialog.open();
    },
    close() {
      this.$refs.dialog.close();
    },
    init(currentPassword = '') {
      // 現在のパスワードが入力済の場合、強制パスワード変更とみなす
      if (currentPassword) this.$set(this, 'isForce', true);

      // 秘密の質問の登録有無確認
      if (!this.userDetail().secretQuestionFlg)
        this.$set(this, 'isForceCreateSecretQuestion', true);

      // 入力値を初期化
      this.$set(this, 'currentPassword', currentPassword);
      this.$set(this, 'newPassword', '');
      this.$set(this, 'newPasswordConfirm', '');
      if (this.$refs.observer) {
        // 入力バリデーションを初期化
        this.$refs.observer.reset();
      }
    },
    onComplete() {
      this.$emit('onComplete');
    },

    async onClickUpdateButton() {
      // バリデーション
      if (await this.$refs.observer.validate()) {
        // 編集項目の保持
        const condition = {
          ...(this.currentPassword && {
            currentPassword: this.currentPassword,
          }),
          ...(this.newPassword && {
            newPassword: this.newPassword,
          }),
          ...(this.newPasswordConfirm && {
            newPasswordConfirm: this.newPasswordConfirm,
          }),
          ...(this.secretQuestion.questionId && {
            secretQuestion: this.secretQuestion,
          }),
        };
        this.$emit('onSuccess', condition);
      }
    },
    onUpdateSuccess() {
      this.$refs.completedDialog.open(
        this.$t('title.agency.passwordUpdateResult'),
        this.$t('success.passwordUpdated')
      );
    },
    onErrorUpdate(message) {
      this.$refs.errorDialog.open(
        this.$t('title.agency.passwordUpdateResult'),
        message
      );
    },
  },
};
</script>
