import {
  OfficeType,
  OfficeTypeDisplay,
  RoleType,
  RoleTypeMap,
  RoleCondition,
  RoleConditionMap,
  RoleConditionMapItem,
  RoleTypeDisplay,
  RolePriority,
} from '@/lib/const';

export class Role {
  // 対象が下位権限であるかどうか
  static isSub(loginUser, targetUser) {
    // 対象の権限値が自分以上であるか
    return RolePriority[loginUser] <= RolePriority[targetUser];
  }

  // 権限変更が実施可であるかどうか
  static isUpdatable(loginUserRole, oldRole, newRole) {
    // 操作ユーザーが営業店でない場合、可とする
    if (loginUserRole !== RoleType.SalesAdmin) return true;

    // 営業店から別の権限への変更の場合、不可とする
    if (
      [RoleType.SalesAdmin].includes(oldRole) &&
      ![RoleType.SalesAdmin].includes(newRole)
    )
      return false;

    // 代理店から別の権限への変更の場合、不可とする
    if (
      [RoleType.AgencyAdmin, RoleType.AgencyUser].includes(oldRole) &&
      ![RoleType.AgencyAdmin, RoleType.AgencyUser].includes(newRole)
    )
      return false;

    return true;
  }

  // 所属店であるかどうか
  static isBelongs(data, officeId, userId, role) {
    // 代理店（担当者）の場合、担当案件のみとする
    if (role === RoleType.AgencyUser)
      return data.chargePersonId === userId && data.officeId === officeId;
    return data.officeId === officeId;
  }

  // 自分であるかどうか
  static isSelf(user, chargePersonId) {
    return user.id === chargePersonId;
  }

  // 本社ユーザーであるかどうか
  static isHead(role) {
    return this.getType(role) == OfficeType.Head;
  }

  // 営業店ユーザーであるかどうか
  static isSales(role) {
    return this.getType(role) == OfficeType.Sales;
  }

  // 代理店ユーザーであるかどうか
  static isAgency(role) {
    return this.getType(role) == OfficeType.Agency;
  }

  // 参照できるかどうか
  static canShow(role, key) {
    return (
      !RoleConditionMapItem.Accept.Read[key] ||
      RoleConditionMapItem.Accept.Read[key].includes(role)
    );
  }

  // 編集できるかどうか
  static canEdit(role, key) {
    return (
      !RoleConditionMapItem.Accept.Update[key] ||
      RoleConditionMapItem.Accept.Update[key].includes(role)
    );
  }

  // 権限タイプの取得
  static getType(role) {
    let type;
    Object.keys(RoleTypeMap).forEach(key => {
      if (RoleTypeMap[key].includes(role)) type = key;
    });
    return type;
  }

  // ユーザー種別の取得
  static getUserType(role) {
    return this.isHead(role)
      ? 'head'
      : this.isAgency(role)
      ? 'agency'
      : 'sales';
  }

  // 事業所IDの取得
  static getOfficeId(user) {
    return this.isAgency(user.role) ? user.agencyId : user.salesId;
  }

  // 営業店IDの取得
  static getSalesId(user) {
    return this.isAgency(user.role) ? null : user.salesId;
  }

  // 代理店IDの取得
  static getAgencyId(user) {
    return this.isAgency(user.role) ? user.agencyId : null;
  }

  // 権限カテゴリーの取得
  static getOfficeType(role) {
    // カテゴリーを探索する
    return Number(
      Object.keys(RoleTypeMap).find(category =>
        // 対象の権限を含むカテゴリーの場合
        RoleTypeMap[category].includes(role)
      )
    );
  }

  // 事業所種別名の取得
  static getOfficeName(num) {
    return OfficeTypeDisplay[num];
  }

  // 権限名の取得
  static getName(num) {
    return RoleTypeDisplay[num];
  }

  // 代理店権限値の取得
  static getAgencyValue(isAdmin) {
    return Number(isAdmin) === 1 ? RoleType.AgencyAdmin : RoleType.AgencyUser;
  }

  // 代理店管理者フラグの取得
  static getAgencyIsAdmin(role) {
    return role === RoleType.AgencyAdmin ? 1 : 0;
  }

  // 権限値群の取得
  static getValues(officeType) {
    return RoleTypeMap[officeType];
  }

  // 権限の選択肢の取得
  static getChoices(role, targetRole) {
    return (
      Object.keys(RoleTypeDisplay)
        .filter(key => {
          // 操作可能な権限であった場合
          return this.isSub(role, key);
        })
        .filter(key => {
          // 対象の権限が存在しない(編集操作でない)場合、可とする
          if (!targetRole) return true;
          // 変更可能な権限であった場合
          return this.isUpdatable(role, targetRole, key);
        })
        // プルダウン選択用の形式に変換
        .map(key => ({
          text: RoleTypeDisplay[key],
          value: key,
        }))
    );
  }

  // ボタンが押下可であるかどうか
  static isButtonEnabled(loginUser, buttonName, data) {
    const { id: userId, role, salesId, agencyId } = loginUser;
    // 許容権限
    const roles = RoleConditionMap.Accept[buttonName];
    // 許容権限が存在しない場合、押下不可とする
    if (!roles) return false;

    // 許容範囲
    const condition = roles[role];
    // 許容範囲が存在しない場合、押下不可とする
    if (!condition) return false;
    // 許容範囲が全ての場合、押下可とする
    if (condition === '*') return true;

    const officeId = this.isAgency(role) ? agencyId : salesId;

    switch (Number(condition)) {
      case RoleCondition.Type.NotSelf:
        return !this.isSelf(loginUser, data.user.id);
      case RoleCondition.Type.Sub:
        return this.isSub(role, data.user.role);
      case RoleCondition.Type.NotSelfSub:
        return (
          !this.isSelf(loginUser, data.user.id) &&
          this.isSub(role, data.user.role)
        );
      case RoleCondition.Type.Belongs:
        return this.isBelongs(data, officeId, userId, role);
      case RoleCondition.Type.NotBelongs:
        return !this.isBelongs(data, officeId, userId, role);
      case RoleCondition.Type.BelongsData:
        return this.isBelongs(data, officeId, userId, role);
    }

    return true;
  }
}
