<template>
  <v-navigation-drawer
    app
    v-model="inputValue"
    clipped
    :mobile-breakpoint="breakPoint"
    :touchless="true"
    width="300"
  >
    <v-spacer class="mt-2" />

    <v-list dense nav>
      <v-list-item-group v-model="index">
        <template v-for="link in links">
          <template v-if="link.grouplist">
            <v-list-group
              :prepend-icon="link.icon"
              :key="link.title"
              :value="false"
              :type="link.type"
              :role="link.role"
            >
              <template v-slot:activator>
                <v-list-item-title>{{ link.title }}</v-list-item-title>
              </template>
              <v-list-item
                v-for="item in link.items"
                :key="item.key"
                :to="item.to"
              >
                <v-list-item-icon />
                <v-list-item-title v-text="$t(item.title)" />
              </v-list-item>
            </v-list-group>
          </template>
          <template v-else>
            <v-list-item
              :key="link.title"
              :to="link.to"
              :type="link.type"
              :role="link.role"
            >
              <v-list-item-icon>
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ link.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </template>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    links: Array,
  },
  data: () => ({
    index: 0,
    breakPoint: 5000,
  }),
  mounted() {
    this.updateNavigationState();
  },
  computed: {
    inputValue: {
      get() {
        return this.drawer();
      },
      set(val) {
        this.setDrawer(val);
      },
    },
  },
  methods: {
    ...mapActions('ui', ['setDrawer']),
    ...mapGetters('ui', ['drawer']),

    // ナビゲーションの状態を更新する
    updateNavigationState() {
      // ウィンドウの横幅が基準点以上であるかどうかで判定
      this.setDrawer(window.innerWidth >= this.breakPoint);
    },
  },
};
</script>
