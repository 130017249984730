import {
  CognitoUser,
  CognitoUserPool,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';
import { Config, CognitoIdentityCredentials } from 'aws-sdk';
import { UserIdKey } from '@/lib/const';

/**
 * Cognito操作クラス
 * Vueのmixinを使用し、this.$cognitoといった形で呼び出す
 */
export default class Cognito {
  configure(config) {
    this.data = {
      UserPoolId: config.UserPoolId,
      ClientId: config.ClientId,
    };

    this.userPool = new CognitoUserPool(this.data);

    Config.region = config.region;
    Config.credentials = new CognitoIdentityCredentials({
      IdentityPoolId: config.IdentityPoolId,
    });

    this.options = config;
  }

  static install(Vue, options) {
    Object.defineProperty(Vue.prototype, '$cognito', {
      get() {
        return this.$root._cognito;
      },
    });

    Vue.mixin({
      beforeCreate() {
        if (this.$options.cognito) {
          this._cognito = this.$options.cognito;
          this._cognito.configure(options);
        }
      },
    });
  }

  /**
   * username, passwordでログイン
   */
  login(username, password) {
    const userData = { Username: username, Pool: this.userPool };
    const cognitoUser = new CognitoUser(userData);
    const authenticationData = { Username: username, Password: password };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: result => {
          resolve(result);
        },
        onFailure: err => {
          reject(err);
        },
        // 初回認証時はパスワードの変更が要求されるので、仮パスワードと同じパスワードを再設定する
        // 新しいパスワード設定等は、APIサーバーとの間で行う
        newPasswordRequired() {
          cognitoUser.completeNewPasswordChallenge(password, {}, this);
        },
      });
    });
  }

  /**
   * ログアウト
   */
  logout() {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.currentUser();
      cognitoUser.getSession(err => {
        if (err) return reject(err);

        cognitoUser.globalSignOut({
          onSuccess: () => {
            this.clearUserId();
            resolve();
          },
          onFailure: error => reject(error),
        });
      });
    });
  }

  /**
   * ログインしているかの判定
   */
  isAuthenticated() {
    const cognitoUser = this.userPool.getCurrentUser() || this.currentUser();
    return new Promise((resolve, reject) => {
      if (!cognitoUser) return reject(cognitoUser);

      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err);
        } else {
          if (!session.isValid()) {
            reject(session);
          } else {
            resolve(session);
          }
        }
      });
    });
  }

  /**
   * パスワード変更
   */
  changePassword(oldPassword, newPassword) {
    const cognitoUser = this.userPool.getCurrentUser() || this.currentUser();

    return new Promise((resolve, reject) => {
      if (!cognitoUser) return reject(cognitoUser);
      cognitoUser.getSession(err => {
        if (err) reject(err);
        cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
          if (err) reject(err);

          resolve(result);
        });
      });
    });
  }

  currentUser() {
    let currentUser = this.userPool.getCurrentUser();

    if (!currentUser) {
      try {
        currentUser = new CognitoUser({
          Username: localStorage.getItem(UserIdKey),
          Pool: this.userPool,
          Storage: localStorage,
        });
      } catch {
        currentUser = null;
      }
    }

    return currentUser;
  }

  setUserId(id) {
    localStorage.setItem(UserIdKey, id);
  }

  clearUserId() {
    localStorage.removeItem(UserIdKey);
  }

  clearStorage() {
    localStorage.clear();
  }
}
